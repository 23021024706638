import {BrowserRouter as Router,Switch,Route,Link} from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Nfound from './pages/Nfound';
import Slide from './components/Slide';
import New from './components/New';
import Header from './components/Header';
import Footer from './components/Footer';
import Packages from './pages/Packages';
ReactDOM.render(
  <>
  <Router>
  <div class="container" id="hide">
            <div class="row">
             <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 imo">
                 <i class='fa fa-youtube'></i> &nbsp; <i class='fa fa-facebook'></i> &nbsp; <i class='fa fa-whatsapp'></i> &nbsp; <i class='fa fa-twitter'></i> &nbsp; <i class='fa fa-google'></i>
                 <i class='fa fa-envelope' style={{marginTop:'10px'}}> &nbsp; 𝒶𝓂𝒾𝓉𝒷𝒾𝓈𝓌𝒶𝓈𝟪𝟨𝟧@𝑔𝓂𝒶𝒾𝓁.𝒸𝑜𝓂</i>
                 
                 </div>
                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <center><h1 style={{textShadow:'5px 5px 10px #d4d4d4'}}><font color="#266494">𝓐𝓶𝓲𝓽</font> <font color="#300b27">𝓢𝓸𝓵𝓾𝓽𝓲𝓸𝓷</font></h1></center>
                    
                 </div>
                 <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 imo" style={{textAlign:'right'}}>
                     <i class='fa fa-youtube'></i> &nbsp; <i class='fa fa-facebook'></i> &nbsp; <i class='fa fa-whatsapp'></i> &nbsp; <i class='fa fa-twitter'></i> &nbsp; <i class='fa fa-google'></i>
                     <i class='fa fa-phone ck' style={{marginTop:'10px'}}> &nbsp; +917001265296 / +918001511769</i> &nbsp; <i class='fa fa-adjust dark' onclick="dark()" style={{marginTop:'10px'}}></i>
                     
                 </div>
                 </div>
                 </div>
  <nav class="navbar navbar-expand-lg navbar-dark navbar-light  mobile" style={{backgroundColor:'#19294A'}}>
  <a class="navbar-brand" href="index.php"><img class="img-fluid" src="https://amitsolution.co.in/ok.png" style={{height:'40px'}}/><b style={{textShadow:'2px 2px 4px #000'}}>Amit Solution</b></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav  ml-auto pr-5 text-capitalize">
      <li class="nav-item blink active">
        <Link class="nav-link blink" to="/" onclick="myfun5()">Home <span class="sr-only">(current)</span></Link>
      </li>
      <li class="nav-item blink">
        <Link class="nav-link blink" to="about" onclick="myfun4()">About Us</Link>
      </li>
      <li class="nav-item blink">
        <Link class="nav-link blink" to="packages" onclick="myfun3()">Package</Link>
      </li>
      <li class="nav-item blink">
        <a class="nav-link blink" href="https://www.payumoney.com/paybypayumoney/#/1FE54E6263FF20707183604E009C78AB" target="#blank">Payment Now</a>
      </li>
      <li class="nav-item dropdown blink">
        <a class="nav-link dropdown-toggle blink" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Projects
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="#website" onclick="myfun()">Website</a>
          <a class="dropdown-item" href="#website">Web Software</a>
          <a class="dropdown-item" href="#payment" onclick="myfun2()">Payment Gateway</a>
        </div>
      </li>
      <li class="nav-item blink">
        <a class="nav-link blink" href="#" data-toggle="modal" data-target="#exampleModal">Contact Us</a>
      </li>
      <li class="nav-item blink">
       <a class="nav-link blink" href="#" onclick="login2()">Login</a>
      </li>
    </ul>
      
  </div>
</nav>


<Slide/>
<Switch>  
<Route path="/" component={Home} exact></Route>
  <Route path="/about" component={About}></Route>
  <Route path="/packages" component={Packages}></Route>
  <Route component={Nfound}></Route>
  </Switch>
  </Router>
  <New/>
  <Footer/>
  </>,
  document.getElementById('root')
);
