function Packages()
{
    return <>
    <div class="row my-3 shadow0" id="website">
    <div class="col-sm-3">
         <div class="card-header" id="Package" style={{color:'white',backgroundColor:'#d9913f'}}>
            <div class="card-title">
                <h5>Prospect Panel</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
           <img src="https://amitsolution.co.in/website/c4.jpg" class="img-responsive" style={{height:'auto',width:'100%'}}/>               
<div class="card0">
          <a href="#" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 shadow0">
          <div class="card-header bg-success" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>Blood Donation</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/c3.jpg" class="img-responsive" style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://blooddonor.licsubratadas.com/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 shadow0">
          <div class="card-header bg-dark" style={{color:'white',backgroundColor:'#139ea8'}}>
            <div class="card-title">
                <h5>Migration System</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/t.jpg" class="img-responsive" style={{height:'auto',width:'100%'}}/> 
         <div class="card0">
          <a href="https://project.amitsolution.co.in/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 shadow0">
          <div class="card-header bg-danger" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>LIC Agent</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/c1.jpg"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://licsubratadas.com/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header bg-warning" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>Payment Gateway</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/p.png"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://licsubratadas.com/Payment" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header bg-dark" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>Online Recharge</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/r.png"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://fastrecharge.migrationapply.xyz/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header bg-primary" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>Online Form</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in//website/f.png"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://licsubratadas.com/LIC/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>Decoration</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/c2.jpg"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://decorations.licsubratadas.com/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header" style={{color:'white',backgroundColor:'#266494'}}>
            <div class="card-title">
                <h5>Demo1</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/demo1.jpg"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://amitsolution.co.in/Demo/index2.php" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header" style={{color:'white',backgroundColor:'#8812FF'}}>
            <div class="card-title">
                <h5>Demo2</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/demo2.jpg"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://amitsolution.co.in/Demo/index.php" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header" style={{color:'white',backgroundColor:'#D9913F'}}>
            <div class="card-title">
                <h5>Demo3</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in/website/demo3.jpg"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://amitsolution.co.in/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 mt-2 col-xl-3 shadow0 web">
          <div class="card-header" style={{color:'white',backgroundColor:'#692925'}}>
            <div class="card-title">
                <h5>Event Pass Genaration</h5>
            </div>
        </div>
       <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
          <img src="https://amitsolution.co.in//website/event.png"  style={{height:'auto',width:'100%'}}/>     
         <div class="card0">
          <a href="https://amitsolutions.000webhostapp.com/EventPass/" target="#blank" class="btn btn-info">Visit Now</a> 
       </div>
       </div> 
       
    </div>
</div>
    </>
}
export default Packages;