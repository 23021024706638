function About()
{
    return <>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 shadow0 mt-3 mb-3">
    <div class="card-header" style={{color:'white',background:'black'}}>
        <div class="card-title">
            <h5>About Us</h5>
        </div>
    </div>
    <div class="card-body" style={{backgroundColor:'#faf7f9',boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                      <h4>I'm Available For Freelancer Projects</h4>
                       
                   </div>
                   </div>
    </>
}
export default About;