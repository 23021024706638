function Home()
{
    return <>
    <div class="row my-3 shadow0 load" id="pack">
    <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
         <div class="card-header" id="Package" style={{color:'white',backgroundColor:'#d9913f'}}>
            <div class="card-title">
                <h5>Package 4999/-</h5>
            </div>
        </div>
       <div class="card-body shadow pack" style={{backgroundColor:'#faf7f9'}}>
           <ul>
         <li>1-4 Pages</li>
         <li>1 Domain for 1 Year</li>
         <li>Mobile Friendly Responsive Design</li>
         <li>2 GB Space, Unmetered Bandwidth</li>
         <li>Free SSL Certificate for 1 Year</li>
         <li>4 Sub Domain,7 Database</li>
         <li>6 Domain Email</li>
         <li>Contact Form</li>
           </ul>                  
<div class="card0">
          <a href="#" class="btn btn-info" data-toggle="modal" data-target="#exampleModal">Contact Now</a> 
       </div>
       </div> 
    </div>
     <div class="col-sm-3 shadow0 load">
          <div class="card-header bg-success" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>Package 5999/- <img src='https://amitsolution.co.in/new.gif'/></h5>
            </div>
        </div>
       <div class="card-body shadow pack"  style={{backgroundColor:'#faf7f9'}}>
          <ul>
         <li>1-5 Pages</li>
         <li>1 Domain for 1 Year</li>
         <li>Mobile Friendly Responsive Design</li>
         <li>2 GB Space, Unmetered Bandwidth</li>
         <li>Free SSL Certificate for 1 Year</li>
         <li>4 Sub Domain,7 Database</li>
         <li>6 Domain Email</li>
         <li>Contact Form</li>
           </ul>      
         <div class="card0">
          <a href="#" class="btn btn-info" data-toggle="modal" data-target="#exampleModal">Contact Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 shadow0 load">
          <div class="card-header bg-dark" style={{color:'white',backgroundColor:'#139ea8'}}>
            <div class="card-title">
                <h5>Package 8999/-</h5>
            </div>
        </div>
       <div class="card-body shadow pack" style={{backgroundColor:'#faf7f9'}}>
          <ul>
         <li>1-7 Pages</li>
         <li>1 Domain for 1 Year</li>
         <li>Mobile Friendly Responsive Design + Customize Design</li>
         <li>30 GB Space, Unmetered Bandwidth</li>
         <li>Free SSL Certificate for 1 Year</li>
         <li>Unlimited Sub Domain</li>
         <li>10 Domain Email</li>
         <li>Contact Form</li>
           </ul>      
         <div class="card0">
          <a href="#" class="btn btn-info" data-toggle="modal" data-target="#exampleModal">Contact Now</a> 
       </div>
       </div> 
       
    </div>
     <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 shadow0 load">
          <div class="card-header bg-danger" style={{color:'white',backgroundColor:'#1e094d'}}>
            <div class="card-title">
                <h5>Package 12999/-</h5>
            </div>
        </div>
       <div class="card-body shadow pack"  style={{backgroundColor:'#faf7f9'}}>
          <ul>
         <li>1-10 Pages</li>
         <li>1 Domain for 1 Year</li>
         <li>Mobile Friendly Responsive Design + Customize Design</li>
         <li>100 GB Space, Unmetered Bandwidth</li>
         <li>Free SSL Certificate for 1 Year</li>
         <li>Unlimited Sub Domain</li>
         <li>20 Domain Email</li>
         <li>Contact Form</li>
           </ul>       
         <div class="card0">
          <a href="#" class="btn btn-info" data-toggle="modal" data-target="#exampleModal">Contact Now</a> 
       </div>
       </div> 
       
    </div>
</div>
    </>
}
export default Home;