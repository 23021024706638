function Slide()
{
    return <>
    <div class="container-fluid ok" style={{backgroundColor:'#f2f2f2',width:'100%',overflow:'hidden'}}>
           <div class="row" style={{marginTop:'3px'}}>
               <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8  sld">
                  
                  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
  </ol>
  <div class="carousel-inner" style={{height:'340px',position:'relative'}}>
    <div class="carousel-item active">
      <img class="d-block w-100 " src="https://amitsolution.co.in/sl1.jpg" alt="First slide"/>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="https://amitsolution.co.in/sl6.jpg" alt="Second slide"/>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="https://amitsolution.co.in/sl7.jpg" alt="Third slide"/>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="https://amitsolution.co.in/sl9.jpg" alt="Third slide"/>
    </div>
  </div>
  <a class="carousel-control-prev ic" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true" style={{visibility: 'hidden'}}></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next ic" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true" style={{visibility: 'hidden'}}></span>
    <span class="sr-only">Next</span>
  </a>
</div>
                  
               </div>
               <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 shadow0">
                   <div class="card-header" style={{color:'white',background:'#1E3A5A'}}>
                       <div class="card-title">
                           <h5>Contact Us</h5>
                       </div>
                   </div>
                  
                   <div class="card-body" style={{backgroundColor:'#faf7f9',boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                       <div class="col-sm-12">
                       <input type="text" class="form-control" placeholder="Enter Name" id="name0"/>
                       </div>
                       <div class="col-sm-12" style={{marginTop:'5px'}}>
                       <input type="email" class="form-control" placeholder="Enter Email Id" id="email"/>
                       </div>
                       <div class="col-sm-12" style={{marginTop:'5px'}}>
                       <input type="number" class="form-control" placeholder="Enter Mobile No" id="number"/>
                       </div>
                       <div class="col-sm-12" style={{marginTop:'5px'}}>
                       <textarea id="msg" class="form-control" placeholder="Enter Message"></textarea>
                       </div>
                       <div class="col-sm-12" style={{marginTop:'5px'}}>
                       <button type="submit" class="btn btn-primary btn-design bt2"  onclick="abc()"><div class='txt-btn2'>Submit</div></button>
                       
                      &nbsp;<input type="reset" value="Reset" onclick="reset0()" class="btn btn-danger btn-design2"/>
                       </div>
                       
                   </div>
                   
               </div>
               
               
               </div>
               
           </div> 
    </>
}
export default Slide;