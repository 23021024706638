function New()
{
    return <>
<div class="row" id="about" style={{display:'none',marginTop:'10px'}}>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card-header bg-dark" style={{color:'white',backgroundColor:'#1e094d'}}>
              <div class="card-title">
                  <h5>About Us</h5>
              </div>
          </div>
         <div class="card-body shadow" style={{backgroundColor:'#faf7f9'}}>
            <p id="view"></p></div> 
      </div>
  </div>
  <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 m-0 p-0">
          <div class="parallax">
              <h4  style={{position: 'absolute',top: '40%',left: '50%',transform: 'translate(-50%, -50%)',color:'white'}}><i class="fa fa-quote-left"></i> I'm Available For Freelancer Projects <i class="fa fa-quote-right"></i></h4>
              <h6 class="my-4" id="hide" style={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',color:'white'}}><i class="fa fa-quote-left"></i> Need for Responsive Web Design <i class="fa fa-quote-right"></i></h6>
             <a  href="#" data-toggle="modal" data-target="#exampleModal" class='btn btn-default my-3 shadow0' style={{position: 'absolute',top: '70%',left: '50%',transform: 'translate(-50%, -50%)',color:'white',background:'#ff7230',outline:'none'}}>Join Us</a>
          </div>
      </div>
  </div>
  </>
}
export default New;