function Footer()
{
    return <>
    <div class="container-fluid footer"  style={{backgroundColor:'#121924',color:'white'}}>
    <div>
       <div class="row">
           <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="card-body" data-aos="fade-right">
                  <h4>Contact Us</h4><hr/>
                <i class='fa fa-home'> Nalagola,Bamangola,Malda,733124</i> <br/>
                <i class='fa fa-envelope' style={{marginTop:'10px'}}> &nbsp; amitbiswas865@gmail.com</i>
                <i class='fa fa-phone' style={{marginTop:'10px'}}> +917001265296 / 8001511769</i>
                <br/>
                <h4  style={{marginTop:'10px'}}>News Later</h4>
                <div class="row">
                
                <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <input type='email' class='form-control' id='news' placeholder="Enter Email"/>
                
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              
                <button type="submit" class="btn btn-primary bt" onclick="news()"><div class='txt-btn'>Send </div></button>
                </div>
                </div>
              </div> 
              
         </div>
           <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="card-body" data-aos="zoom-in">
                  <h4>Important Links</h4><hr/>
                <ul>
                    <a href="#" class="blink" onclick="myfun5()"><li>Home</li></a>
                    <a href="#about" class="blink" onclick="myfun4()"><li>About</li></a>
                    <li>Service</li>
                    <a href="#website" class="blink" onclick="myfun()"><li>Project</li></a>
                    <a href="#" class="blink" data-toggle="modal" data-target="#exampleModal"><li>Contact Us</li></a>
                </ul>
                <i class='fa fa-youtube'></i> &nbsp; <i class='fa fa-facebook'></i> &nbsp; <i class='fa fa-whatsapp'></i> &nbsp; <i class='fa fa-twitter'></i> &nbsp; <i class='fa fa-google'></i>
                <br/>
               
              </div>  
           </div>
           <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
               <div class="card-body" data-aos="fade-down">
                  <h4>Location</h4><hr/>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.084836369452!2d88.42852901432877!3d25.26773143503736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fb15858a05f137%3A0x326b0bd245c03cf6!2sNalagola%20Amit%20Solution!5e0!3m2!1sen!2sin!4v1592985631709!5m2!1sen!2sin" width="400" height="200" frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div> 
           </div>
       </div> 
    
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <center><i class='fa fa-copyright'> 2020</i> Copyright : Amit Solution <br/>
     Customer Rating: <i class='fa fa-star act'></i><i class='fa fa-star act'></i><i class='fa fa-star act'></i><i class='fa fa-star act'></i><i class='fa fa-star'></i> 4.6
    </center>
    </div>
    </div>
    </div>
    </>
}
export default Footer;